<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <form @submit.prevent @keyup.enter="insertBuyerKey">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Nome"
          :type="campoInvalido('name', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('name', 'data') ? 'Insira um nome' : ''"
        >
          <b-input
            placeholder="Insira um nome para a chave"
            :disabled="disableFields"
            v-model="data.name"
          >
          </b-input>
        </b-field>

        <b-field>
          <b-button
            class="button is-primary is-fullwidth add-button"
            :loading="loading.add"
            :disabled="disableFields"
            @click.prevent.stop="insertBuyerKey()"
          >
            Adicionar
          </b-button>
        </b-field>
      </b-field>
    </form>

    <hr />

    <b-table
      v-if="companyAdministrator && keys.length"
      paginated
      class="table is-fullwidth"
      :data="keys"
    >
      <b-table-column numeric label="ID" width="40" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>

      <b-table-column sortable field="name" label="Nome" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column
        sortable
        field="key"
        label="Chave"
        :visible="keys.some(element => element.key)"
        v-slot="props"
      >
        <strong v-if="props.row.key">{{ props.row.key }}</strong>
      </b-table-column>

      <b-table-column
        centered
        sortable
        field="createdAt"
        label="Inserida em"
        width="140"
        v-slot="props"
      >
        {{ new Date(props.row.createdAt).toLocaleDateString('pt-BR') }}
      </b-table-column>

      <b-table-column centered :visible="!disableFields" v-slot="props">
        <b-tooltip v-if="props.row.key" label="Copiar Chave">
          <b-button class="mr-1" @click.prevent.stop="copyKey(props.row.key)">
            <b-icon icon="content-copy"></b-icon>
          </b-button>
        </b-tooltip>

        <b-tooltip label="Excluir">
          <b-button
            type="is-danger"
            :disabled="disable.remove"
            @click.prevent.stop="removeBuyerKey(props.row.id)"
          >
            <b-icon icon="delete"></b-icon>
          </b-button>
        </b-tooltip>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import campoMixin from '@/mixins/formulario';

export default {
  name: 'BuyerKeys',
  mixins: [campoMixin],
  data() {
    const data = {
      name: '',
    };

    const disable = {
      remove: false,
    };

    const loading = {
      general: false,
      add: false,
    };

    return {
      data,
      disable,
      loading,
    };
  },
  validations: {
    data: {
      name: { required },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('companiesKeys', ['keys']),
    ...mapGetters({
      logged: 'getLogged',
      users: 'getCompanyUsers',
      user: 'getUser',
    }),
    companyAdministrator() {
      return this.users.some(user => user.id === this.user.id && user.function === 'ADMINISTRADOR');
    },
    disableFields() {
      return !this.logged || !this.companyAdministrator;
    },
  },
  methods: {
    ...mapActions(['getUsers']),
    ...mapActions('companiesKeys', ['getCompanyKeys', 'insertCompanyKey', 'removeCompanyKey']),
    async copyKey(key) {
      try {
        await this.$copyText(key);
        this.$alerta('Chave copiada', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao copiar convite', 'is-danger');
      }
    },
    async insertBuyerKey() {
      if (this.$v.data.$invalid) {
        this.$v.data.$touch();
        return;
      }

      this.loading.add = true;
      try {
        await this.insertCompanyKey({ companyId: this.company.id, name: this.data.name });
        this.$alerta('Chave adicionada', 'is-success');
        this.data.name = '';
      } catch (error) {
        this.$alerta('Erro ao adicionar chave', 'is-danger');
      } finally {
        this.loading.add = false;
      }
    },
    async removeBuyerKey(keyId) {
      this.disable.remove = true;
      try {
        await this.removeCompanyKey({ companyId: this.company.id, keyId });
        this.$alerta('Chave Removida', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao remover chave', 'is-danger');
      } finally {
        this.disable.remove = false;
      }
    },
  },
  async created() {
    this.loading.general = true;
    try {
      await this.getUsers(this.$route.params.id);
      if (this.companyAdministrator) {
        this.getCompanyKeys({ companyId: this.$route.params.id });
      }
    } catch (error) {
      this.$alerta('Erro ao buscar dados', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  height: 100%;
}

@media (max-width: 768px) {
  .add-button {
    margin-top: 1rem;
  }
}
</style>
